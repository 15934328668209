import { render } from "./TargetSettingList.vue?vue&type=template&id=4e1ffd81"
import script from "./TargetSettingList.vue?vue&type=script&lang=ts"
export * from "./TargetSettingList.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTh,QTd,QToggle,QBtn,QSpace});
