
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { isValidUrl } from '@/utils/validators'
import { ITargetSetting, ITargetSettingDetail } from '@/utils/types'
import { ACTION_TARGET_SETTING } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: { CLoading, TestModeSelector },
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class TargetSettingFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: ITargetSetting

  loading = true
  isTestMode = false
  form: ITargetSetting = {
    _id: '',
    target_name: '',
    app_id: '',
    is_active: false,
    is_test_mode: false,
    prod_detail: {
      success_page_urls: [
        {
          is_full_match: false,
          url: '',
        },
      ],
    },
    test_detail: {
      success_page_urls: [
        {
          is_full_match: false,
          url: '',
        },
      ],
    },
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get requiredRule() {
    const requiredRule = {
      target_name: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.target_setting.target_name'),
      }),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.isNew
      ? this.$t('label.target_setting.add_new_target_setting')
      : this.$t('label.target_setting.edit_target_setting')
  }

  handleAddSuccessURL(index: number): void {
    let detail: ITargetSettingDetail
    if (this.isTestMode) {
      detail = this.form.test_detail ?? {}
    } else {
      detail = this.form.prod_detail ?? {}
    }
    if (detail) {
      if (!detail.success_page_urls) {
        detail.success_page_urls = []
      }
      detail.success_page_urls.splice(index + 1, 0, {
        url: '',
        is_full_match: true,
      })
    }
  }

  handleDeleteSuccessURL(index: number): void {
    let detail: ITargetSettingDetail
    if (this.isTestMode) {
      detail = this.form.test_detail ?? {}
    } else {
      detail = this.form.prod_detail ?? {}
    }
    if (detail && detail.success_page_urls) {
      detail.success_page_urls.splice(index, 1)
    }
  }

  isMatchRegex = (pattern: string, value: string): boolean => {
    // Use try cacth because in somecase string is not valid regex format
    try {
      const regex = new RegExp(pattern, 'g')
      return regex.test(value)
    } catch {
      return false || this.$t('label.target_setting.please_input_a_valid_unique_url')
    }
  }

  customIsValidUrl(url) {
    let error = false
    let detail: ITargetSettingDetail

    if (!isValidUrl(url)) {
      return false || this.$t('label.target_setting.please_input_a_valid_unique_url')
    }

    if (this.isTestMode) {
      detail = this.form.test_detail ?? {}
    } else {
      detail = this.form.prod_detail ?? {}
    }
    if (!detail.success_page_urls) {
      return true
    }
    detail.success_page_urls.forEach((item) => {
      const dups = detail?.success_page_urls?.filter((item1) => {
        return (item1.url === item.url && item.url === url) || this.isMatchRegex(item1.url, item.url)
      })

      if (dups && dups.length > 1) {
        error = true
      } else if (dups && dups.length === 1) {
        if (url === dups[0].url) {
          error = false
        }
      }
    })

    if (error) {
      return false || this.$t('label.target_setting.please_input_a_valid_unique_url')
    }

    return true || ''
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let item = null
    if (this.isNew) {
      this.form.app_id = this.selectedAppId
      this.form.test_detail = this.form.prod_detail
      item = await dispatch(ACTION_TARGET_SETTING.ADD, {
        ...this.form,
      })
    } else {
      item = await dispatch(ACTION_TARGET_SETTING.UPDATE, {
        _id: this.data._id,
        ...this.form,
      })
    }

    if (item) {
      this.$emit('update:onSelect', item)

      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  onDetailCopy() {
    if (this.isTestMode) {
      this.form.test_detail = this.form.prod_detail
    } else {
      this.form.prod_detail = this.form.test_detail
    }
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
    this.loading = false
    this.$nextTick(() => {
      this.$refs.formRef.validate()
    })
  }
}
